import { LevelOrBetter, OptionsLevel, OptionsLevelType } from 'phoenix/constants/OptionsLevels';
import { OptionsOpenClose, TradeActions } from 'phoenix/constants/Trade';
import { ApiCreditDebit, ApiOrderType, ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { OptionQuote, OptionsOpenClose as OptionsOpenCloseType, OptionsPutCall, OptionSymbol, TradeAction } from 'phoenix/redux/models';
import { PositionsStore_Load } from 'phoenix/stores/PositionsStore';
import { ApiPosition } from '../redux/models';
import { floatMath, Sum } from '../util';

type OptionsOrderPermittedArgs = {
    action: ApiTradeAction;
    optionsLevel?: OptionsLevelType;
    optionsPositions?: ApiPosition[]; // All options positions for a security (may consist of several different strikes/expirations)
    quantity: number; // Quantity of options contracts for the order in question
    deliverableCount?: number;
    symbol: string; // OSI
    underlyingPositionQty?: number; // Position quantity in the underlying asset of an equities options order
};

/** @deprecated use GetOptionsOpenClose */
export const GetOpenness = async (symbol: string, accountNumber: string, action: TradeAction): Promise<'Open' | 'Close'> => {
    const osiSym = new OptionSymbol(symbol).toOsiSymbol();

    const allPositions: ApiPosition[] = await PositionsStore_Load();
    const positions = allPositions?.filter((p) => p.optionOsiSymbol === osiSym && p.accountNumber === accountNumber) || [];
    if (!positions?.length) return 'Open';
    const isLong = Sum(positions.filter((p) => typeof p.quantity === 'number' && !isNaN(p.quantity)).map((p) => p.quantity || 0)) >= 0;
    const isBuy = action === 'Buy';

    if (isLong) return isBuy ? 'Open' : 'Close';
    else return isBuy ? 'Close' : 'Open';
};

// Intrinsic value represents the value of an option if executed right now
// It is determined by the difference between the current (last) price of the underlying security and the strike price of an option contract
export const GetOptionIntrinsicValue = ({ putCall, strikePrice, underlyingPrice }: { putCall: OptionsPutCall; strikePrice: number; underlyingPrice: number }): number => {
    // Calls will only have intrinsic value when the underlying price > the strike price
    // Puts will only have intrinsic value when the underlying price < the strike price
    const difference = (putCall === 'call' ? floatMath(underlyingPrice, strikePrice, (a, b) => a - b) : floatMath(strikePrice, underlyingPrice, (a, b) => a - b)) || 0;

    // When the option is out of the money, intrinsic value will always be zero
    return difference <= 0 ? 0 : difference;
};

// Stateless method of determining options order permissions
export function GetOptionsOrderPermitted({
    action,
    optionsLevel,
    optionsPositions = [],
    quantity,
    deliverableCount = 100, // Since we don't allow Sell to Open on adjusted options, this should always be 100 when relevant (covered calls)
    symbol,
    underlyingPositionQty = 0
}: OptionsOrderPermittedArgs): boolean {
    const optSym = new OptionSymbol(symbol);

    // Prevent this from blocking non-options orders
    if (!optSym?.isOption) return true;

    const { putCall, expDate } = optSym;

    if (!optionsLevel) return false;
    // Anyone with at least level 1 can always buy stuff
    if (action === 'Buy') return LevelOrBetter(optionsLevel, OptionsLevel.CallsAndPuts);
    // If we already know they can sell puts, skip all the complicated cover logic below for puts specifically
    if (putCall === 'P' && LevelOrBetter(optionsLevel, OptionsLevel.WriteEquityPuts)) return true;

    const matchingPutCall = optionsPositions.filter((p) => new OptionSymbol(p.secMasterOptionSymbol || p.symbol || '')?.putCall);
    // It's possible to leg into spreads for positions with farther out expiration dates
    const positionsWithValidExpirations = matchingPutCall.filter((p) => {
        const positionExpiration = new OptionSymbol(p.secMasterOptionSymbol || p.symbol || '')?.expDate;
        return expDate <= positionExpiration;
    });
    // Quantity of the exact option positions (exact expiration, put/call, etc)
    const positionQty = Sum(
        optionsPositions
            .filter((p) => {
                if (!p.secMasterOptionSymbol && !p.symbol) return false;
                else {
                    const positionSymbol = new OptionSymbol(p.secMasterOptionSymbol || p.symbol || '');
                    return positionSymbol.osiSymbol === optSym.osiSymbol;
                }
            })
            .map((p) => p.quantity || 0)
    );

    // Quantity of any qualifying options positions (same put/call, expiration same or farther out than order request)
    const spreadQty = Sum(positionsWithValidExpirations.map((p) => p.quantity || 0));

    const underlyingQty = underlyingPositionQty;
    const canBeCovered = underlyingQty / deliverableCount;
    const uncovered = quantity - positionQty - canBeCovered > 0;

    // Check to see if it's a covered call before bothering with spread logic
    if (putCall === 'C' && action === TradeActions.Sell && canBeCovered && LevelOrBetter(optionsLevel, OptionsLevel.CoveredCalls)) return true;

    // User can always close out some or all of an open position
    if (quantity <= positionQty && action === TradeActions.Sell) return true; // Sell to close
    if (positionQty < 0 && quantity <= Math.abs(positionQty) && action === TradeActions.Buy) return true; // Buy to close

    // If user is entitled for spreads
    // they can leg into a spread based on an existing position with an expiration that is the same or later
    if (quantity <= spreadQty && action === TradeActions.Sell) return LevelOrBetter(optionsLevel, OptionsLevel.SpreadsLongStraddles);

    // This should catch anyone below level 4 trying to write a put if they aren't legging in
    if (putCall === 'P') return LevelOrBetter(optionsLevel, OptionsLevel.WriteEquityPuts);
    // For anything else, level 5 or better is required
    if (putCall === 'C' && uncovered) return LevelOrBetter(optionsLevel, OptionsLevel.LevelFive);

    // TODO: Level 6 validation

    return true;
}
export const GetOptionsOpenClose = ({
    positions,
    selectedAccountNumber,
    symbol,
    tradeAction
}: {
    positions: ApiPosition[];
    selectedAccountNumber?: string | null;
    symbol: string;
    tradeAction: ApiTradeAction;
}): OptionsOpenCloseType => {
    const osiSym = new OptionSymbol(symbol).toOsiSymbol();
    if (!selectedAccountNumber) return OptionsOpenClose.Open;

    const relevantPositions =
        positions?.filter((p) => (p.optionOsiSymbol === osiSym || p.secMasterOptionSymbol === osiSym) && p.accountNumber === selectedAccountNumber) || [];

    if (!relevantPositions?.length) return OptionsOpenClose.Open;
    const isLong = Sum(relevantPositions.filter((p) => typeof p.quantity === 'number' && !isNaN(p.quantity)).map((p) => p.quantity || 0)) >= 0;
    const isBuy = tradeAction === 'Buy';

    if (isLong) return isBuy ? OptionsOpenClose.Open : OptionsOpenClose.Close;
    else return isBuy ? OptionsOpenClose.Close : OptionsOpenClose.Open;
};

// There are options levels that allow multi-leg trades for spreads, straddles, etc
// But there are also a few cases where they don't need those permissions (rolling, sell to close and a different buy, 2 buys, etc)
export function GetCanTradeMultiLeg({
    optionsLevel,
    tradesWithOpenClose
}: {
    optionsLevel?: OptionsLevelType;
    tradesWithOpenClose: { openClose: OptionsOpenCloseType; quantity: number; tradeAction: TradeAction }[];
}): boolean {
    // If the account can trade spreads, none of this is necessary to check, just return true
    if (LevelOrBetter(optionsLevel, OptionsLevel.SpreadsLongStraddles)) return true;

    const buys = tradesWithOpenClose.filter((x) => x.tradeAction === TradeActions.Buy);

    // Any user can buy 2 options at once
    if (buys.length > 1) return true;

    const sells = tradesWithOpenClose.filter((x) => x.tradeAction === TradeActions.Sell);
    const sellsToOpen = sells.filter((x) => x.openClose === OptionsOpenClose.Open);

    // Nobody without permissions will be able to sell both legs to open
    if (sellsToOpen.length > 1) return false;

    const closes = tradesWithOpenClose.filter((x) => x.openClose === OptionsOpenClose.Close);

    // Close multiple open positions at once
    if (closes.length > 1) return true;

    // Rolling a covered call
    // This is checking to make sure a user doesn't try to open a larger CC than they're closing
    // It should technically be possible if they have a large enough underlying position
    // But there are "ratio pricing" issues with torch/beta that led us towards this restriction
    const closeIndex = tradesWithOpenClose.findIndex((x) => x.openClose === OptionsOpenClose.Close);
    const closeTrade = tradesWithOpenClose[closeIndex];
    const openTrade = tradesWithOpenClose[closeIndex === 0 ? 1 : 0];
    const result = openTrade?.tradeAction !== closeTrade?.tradeAction && openTrade.quantity <= closeTrade?.quantity;

    return result;
}

export type MultiLegNetAskBidArgs = {
    leg1Quote: OptionQuote;
    leg2Quote: OptionQuote;
    quantity?: number;
    tradeAction?: TradeAction;
    leg2Quantity?: number;
    leg2TradeAction?: TradeAction;
};

export interface MappedQuote extends OptionQuote {
    ask: number;
    bid: number;
    quantity: number;
    tradeAction?: ApiTradeAction;
}

export type MultiLegNetAskBidResult = { netAsk?: number; netBid?: number; mappedQuotes?: MappedQuote[]; midpoint?: number };
export const getMultiLegNetAskBid = ({ leg1Quote, leg2Quote, quantity, tradeAction, leg2Quantity, leg2TradeAction }: MultiLegNetAskBidArgs): MultiLegNetAskBidResult => {
    // All bids and asks are required to calculate these values
    if ([leg1Quote, leg2Quote].some((q) => !q || !q?.ask || !q?.bid)) return {};

    // Only set a default price if both legs have values. Ratio spreads exist so we can't assume 1:1
    if (!quantity || !leg2Quantity) return {};

    // Sort the quotes by quantity
    // When handling ratio spreads estimated cost/credit values are determined by multiplying by the lowest common denominator (0 index)
    const mappedQuotes: MappedQuote[] = [
        // We typecheck bid/ask above, so we knowthey are defined
        { ...(leg1Quote as OptionQuote & { ask: number; bid: number }), ...{ quantity: quantity || 1, tradeAction } },
        { ...(leg2Quote as OptionQuote & { ask: number; bid: number }), ...{ quantity: leg2Quantity || 1, tradeAction: leg2TradeAction } }
    ].sort((a, b) => a.quantity - b.quantity);

    // Sorting allows easily locating smallest and largest values
    const bids = [leg1Quote.bid as number, leg2Quote.bid as number].sort((a, b) => a - b);
    const asks = [leg1Quote.ask as number, leg2Quote.ask as number].sort((a, b) => a - b);

    // Ratio limit pricing is based off the lowest common denominator
    // Limit price is multiplied by the lowest common denominator when calculating "Estimated total cost/credit"
    // Example: Buy 6 @ Bid: $1, Ask: $2, Sell 10 @ Bid: $3, ask $4
    // Calculations will be based off 3/5, not 6/10
    // Net Ask = $17 ((5 * $4) - (3 * $1)) Estimated credit: $5100 | (17 * 3) * 100 (unit factor)
    // Net Bid = $9 ((5 * $3) - (3 * $2)) Estimated credit: $2700 |  (9 * 3) * 100 (unit factor)
    const isDivisible = !(mappedQuotes[1].quantity % mappedQuotes[0].quantity);
    const ratios = mappedQuotes.map((q) => (isDivisible ? q.quantity / mappedQuotes[0].quantity : q.quantity));
    const buyIndex = mappedQuotes.findIndex((q) => q.tradeAction === TradeActions.Buy);
    const sellIndex = mappedQuotes.findIndex((q) => q.tradeAction === TradeActions.Sell);
    const buyRatio = ratios[buyIndex] || 1;
    const sellRatio = ratios[sellIndex] || 1;

    // If both legs are the same action, add up bids and asks
    if (mappedQuotes[0].tradeAction === mappedQuotes[1].tradeAction) {
        const netAsk = floatMath(mappedQuotes[0].ask * ratios[0], mappedQuotes[1].ask * ratios[1], (a, b) => a + b) || 0;
        const netBid = floatMath(mappedQuotes[0].bid * ratios[0], mappedQuotes[1].bid * ratios[1], (a, b) => a + b) || 0;
        return {
            mappedQuotes,
            midpoint: Math.abs(netAsk + netBid) / 2,
            netAsk,
            netBid
        };
    }

    const netAsk = Math.abs(floatMath(bids[0] * buyRatio, asks[1] * sellRatio, (a, b) => a - b) || 0);
    const netBid = Math.abs(floatMath(asks[0] * buyRatio, bids[1] * sellRatio, (a, b) => a - b) || 0);

    // Match up and multiply the bid/ask values by the buy/sell quantities
    return {
        mappedQuotes,
        midpoint: Math.abs(netAsk + netBid) / 2,
        netAsk,
        netBid
    };
};

export const getMultiLegDefaultPrice = ({
    quantity,
    leg1Quote,
    leg2Quote,
    orderType = 'limit',
    tradeAction,
    leg2Quantity,
    leg2TradeAction
}: MultiLegNetAskBidArgs & { orderType?: ApiOrderType }): { initialPrice?: number; debitCredit?: ApiCreditDebit } => {
    // Only set a default price if both legs have values. Ratio spreads exist so we can't assume 1:1
    if (!quantity || !leg2Quantity) return {};

    const multiLegNetAskBid = getMultiLegNetAskBid({
        leg1Quote,
        leg2Quote,
        quantity,
        tradeAction,
        leg2Quantity,
        leg2TradeAction
    });

    const { netAsk, netBid, mappedQuotes } = multiLegNetAskBid;
    const selling = mappedQuotes?.filter((x) => x.tradeAction === 'Sell');
    const sellingQty = selling?.map((x) => x.quantity).reduce((a, b) => a + b, 0);
    const sellPrice = selling?.map((x) => x.bid).reduce((a, b) => a + b, 0) || 0 * (sellingQty || 0);

    const buying = mappedQuotes?.filter((x) => x.tradeAction === 'Buy');
    const buyingQty = buying?.map((x) => x.quantity).reduce((a, b) => a + b, 0);
    const buyPrice = buying?.map((x) => x.ask).reduce((a, b) => a + b, 0) || 0 * (buyingQty || 0);

    const debitCredit = (() => {
        switch (true) {
            case sellPrice > buyPrice:
                return 'Credit';
            case buyPrice > sellPrice:
                return 'Debit';
            default:
                return 'Even';
        }
    })();

    const priceLogic = {
        // For limit orders we try to default to the "best" possible price for the user
        limit: {
            Debit: netBid,
            Credit: netAsk,
            Even: undefined
        },
        // For market orders we use the natural or market price
        market: {
            Debit: netAsk,
            Credit: netBid,
            Even: undefined
        },
        // For market orders we use the natural or market price
        even: {
            Debit: netAsk,
            Credit: netBid,
            Even: undefined
        }
    };

    const priceByOrderType = priceLogic[orderType as 'limit' | 'market'];
    const initialPrice = priceByOrderType[debitCredit];

    return { debitCredit, initialPrice };
};

export const getMultiLegEstimatedTotalCostCredit = ({
    leg2Quantity = 0,
    orderPrice = 0, // user entered limit price, or a default/market price calculated using getMultiLegDefaultPrice
    quantity = 0,
    deliverableCount = 100 // SecurityMetadata.deliverableCount - 100 except for adjusted options
}: {
    leg2Quantity?: number;
    orderPrice?: number;
    quantity?: number;
    deliverableCount: number;
}): number | undefined => {
    if ([leg2Quantity, orderPrice, quantity, deliverableCount].some((x) => !x)) return undefined;
    // Multiplier is lowest common denominator for ratio spreads; these should be equal in all other cases
    const quantityMultiplier = Math.min(leg2Quantity, quantity);

    const estimatedPrice = orderPrice * quantityMultiplier * deliverableCount;

    return estimatedPrice;
};
